.login {
  -webkit-animation: color-change-2x 6s linear 2s infinite alternate both;
  animation: color-change-2x 6s linear 2s infinite alternate both;
}

.login #nav a {
  color: #fff;
}

.login #nav a:hover {
  color: #000 !important;
}

.login #backtoblog a {
  color: #d1a7d1;
}

.login #backtoblog a:hover {
  color: #fff;
}

.login h1 a {
  background-image: url("./../images/sg-logo-login.png") !important;
  background-size: contain;
  background-position: center;
  width: 320px;
  height: 166px;
  margin-bottom: 0;
  filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
}

.login #loginform {
  background: none !important;
  border: none !important;
  border-radius: 20px;
  margin-top: 0;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  box-shadow: 7px 6px 7px rgba(0, 0, 0, 0.2) !important;
}

.login #loginform #user_login {
  border-color: #eae639;
  color: black !important;
  filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
}

.login #loginform #user_pass {
  border-color: #eae639;
  color: black !important;
  filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
}

.login #login_error {
  border-left-color: #dc3232;
  color: #000;
}

.login .message {
  color: #000;
}

.login .button {
  background: #eae639 !important;
  border: 0px;
  box-shadow: none;
  text-shadow: none;
  color: black;
  filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
}

.login .button:hover {
  background: #000 !important;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-1-9 14:20:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */

@-webkit-keyframes color-change-2x {
  0% {
    background: rgba(218, 98, 111, 0.9);
  }

  50% {
    background: rgba(39, 68, 123, 0.9);
  }

  100% {
    background: rgba(62, 144, 178, 0.95);
  }
}

@keyframes color-change-2x {
  0% {
    background: rgba(218, 98, 111, 0.9);
  }

  50% {
    background: rgba(39, 68, 123, 0.9);
  }

  100% {
    background: rgba(62, 144, 178, 0.95);
  }
}

