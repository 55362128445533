@import "generic/variables";

.login {
  //background: linear-gradient(-30deg, #3e90b2,  #000 100%);

  -webkit-animation: color-change-2x 6s linear 2s infinite alternate both;
  animation: color-change-2x 6s linear 2s infinite alternate both;

  #nav a {
    color: #fff;
  }

  #nav a:hover {
    color: #000 !important;
  }


  #backtoblog a {
    color: $grey;
  }

  #backtoblog a:hover {
    color: #fff;
  }

  h1 a {
    background-image: url('../images/sg-logo-login.png') !important;
    background-size: contain;
    background-position: center;
    width: 320px;
    height: 166px;
    margin-bottom: 0;
    filter: drop-shadow(6px 3px 3px rgba(34, 34, 34,0.7));
  }

  #loginform {
    background: none !important;
    border: none !important;
    border-radius: 20px;
    margin-top: 0;
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 7px 6px 7px rgba(0,0,0,.2) !important;
    #user_login {
      border-color: #eae639;
      color: black !important;
      filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
    }
    #user_pass {
      border-color: #eae639;
      color: black !important;
      filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
    }
  }

  #login_error {
    border-left-color: #dc3232;
    color: #000;
  }

  .message {
    color: #000;
  }


  .button {
    background: #eae639 !important;
    border: 0px;
    box-shadow: none;
    text-shadow: none;
    color: black;
    filter: drop-shadow(6px 3px 3px rgba(34, 34, 34, 0.7));
  }

  .button:hover {
    background: #000 !important;
  }


}

/* ----------------------------------------------
 * Generated by Animista on 2020-1-9 14:20:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    background: rgba(218, 98, 111, 0.9);
  }
  50% {
    background: rgba(39, 68, 123, 0.9);
  }
  100% {
    background: rgba(62, 144, 178, 0.95);
  }
}
@keyframes color-change-2x {
  0% {
    background: rgba(218, 98, 111, 0.9);
  }
  50% {
    background: rgba(39, 68, 123, 0.9);
  }
  100% {
    background: rgba(62, 144, 178, 0.95);
  }
}
//background: linear-gradient(-30deg, rgba(218, 98, 111, 0.9) 0%, rgba(39, 68, 123, 0.9) 50%, rgba(62, 144, 178, 0.95) 100%) !important;